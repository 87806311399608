
import { Component, Mixins } from 'vue-property-decorator'
import DashboardWelcome from '@/components/DashboardWelcome/DashboardWelcome.vue'
import DashboardViewer from '@/components/DashboardViewer/DashboardViewer.vue'
import BaseFrame from '@/components/BaseFrame/BaseFrame.vue'
import { EnvironmentMixin } from '@/mixins/environment.mixin'

/**
 * Dashboard-View.
 */
@Component({
  components: {
    DashboardWelcome,
    DashboardViewer,
    BaseFrame
  }
})
export default class Dashboard extends Mixins(EnvironmentMixin) {
  /**
   * Liest die Dashboard-ID aus der Route aus.
   *
   * @returns ID des Dashboards.
   */
  public get id(): number {
    const id = parseInt(this.$route.params.id)

    if (isNaN(id)) {
      throw new Error('Dashboard ID must be a number.')
    }

    if (id < 1) {
      throw new Error('Dashboard ID must be > 0.')
    }

    return id
  }

  /**
   * Speichert die Dashboard ID in den sessionStorage
   */
  public saveID(): void {
    sessionStorage.setItem('nxtDashboardID', this.id.toString())
  }

  /**
   * Vue-Hook `updated`
   */
  public updated(): void {
    this.saveID()
  }

  /**
   * Vue-Hook `created`
   */
  public created(): void {
    this.saveID()
  }
}
