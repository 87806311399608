
import { Component, Vue, Prop } from 'vue-property-decorator'

/**
 * Gibt den Willkommenstext eines Dashboards aus
 */
@Component
export default class DashboardWelcome extends Vue {
  /**
   * Text des Willkommenstextes
   */
  @Prop({ type: String, required: false, default: '' })
  public message!: string
}
